<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import type { FormKitNode } from '@formkit/core'

const submitSuccessful = ref(false)

async function handleSubmit(values: { email: string }, node: FormKitNode) {
  await $fetch('/api/newsletter', { body: { email: values.email }, method: 'post' })
  node.reset()
  submitSuccessful.value = true
}
</script>

<template>
  <div class="bg-primary">
    
<FormKitLazyProvider config-file="true">
<div class="bg-white my-10 mx-9 p-8 flex flex-col gap-4 lg:w-3/4 lg:mx-auto">
      <span class="text-4xl font-roboto-condensed font-medium text-primary_font text-center">
        {{ $t('newsletter.would_like_host_next_year') }}
      </span>
      <p class="text-xl font-roboto-condensed font-light text-center">
        {{ $t('newsletter.sign_up_newsletter') }}
      </p>
      <FormKit
        v-if="!submitSuccessful"
        type="form"
        :form-class="{
          'lg:flex-row': true,
          'lg:gap-x-8': true,
        }"
        :submit-label="$t('newsletter.button_sign_up_newsletter')"
        @submit="handleSubmit"
      >
        <FormKit type="email" name="email" validation="required|email" :placeholder="$t('sign_up.common.email.placeholder')" />
      </FormKit>
      <div v-else class="text-center flex items-center justify-center gap-4">
        <p class="text-green-600">
          {{ $t('newsletter.success_text') }}
        </p>
      </div>
    </div>
</FormKitLazyProvider>

  </div>
</template>
